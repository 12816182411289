import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { renderRoutes } from 'react-router-config';
import AsyncLocalStorage from '@createnextapp/async-local-storage';
import theme from './theme';
import { configureStore } from './store';
import routes from './routes';
import {
  ScrollReset,
  GoogleAnalytics,
  CookiesNotification
} from './components';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/index.scss';
import AppDataContext from 'Helper/AppDataContext';
import Api from 'Helper/ApiHandler';
import useRouter from 'utils/useRouter';

const history = createBrowserHistory();
const store = configureStore();

var api = new Api();

const App = () => {
  const [appContext, setAppContext] = useState({});
  const router = useRouter();

  const value = { appContext, setAppContext };

  // const verifytoken = async () => {
  //   const accessToken = localStorage.getItem('authToken');

  //   const headers = {
  //     Authorization: `Bearer ${accessToken}`
  //   };

  //   api
  //     .post('verifyToken', {}, { headers })
  //     .then(result => {
  //       if (result.success === true) {
  //         router.history.push('/dashboard');
  //         return;
  //       } else {
  //         router.history.push('/auth/login');
  //         return;
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err, 'error catch');
  //     });
  // };
  // const verifytoken = async () => {
  //   const accessToken = localStorage.getItem('authToken');
  //   const headers = {
  //     Authorization: `Bearer ${accessToken}`
  //   };
  //   try {
  //     const result = await api.post(`verifyToken`, {}, { headers });
  //     if (result.success === true) {
  //       console.log('if');
  //       // localStorage.setItem('verifyToken', result?.success);
  //       // router.history.push('/dashboard');
  //       //  this.props.history.push('/');
  //     } else {
  //       // console.log('else');
  //       // router.history.push('/auth/login');
  //     }
  //   } catch (error) {
  //     console.log('Error fetching balance:', error);
  //   }
  // };

  useEffect(() => {
    const currentBalance = async () => {
      try {
        let businessId =
          (await AsyncLocalStorage.getItem('businessid')) ||
          appContext.business;
        if (businessId) {
          const result = await api.get(
            `business/business_balance/${businessId}`
          );
          await AsyncLocalStorage.setItem('wallet', result?.wallet);
        }
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    };

    // verifytoken();
    currentBalance();
  }, [appContext.business, history.location.pathname, api, AsyncLocalStorage]);

  return (
    <StoreProvider store={store}>
      <AppDataContext.Provider value={value}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
              <ScrollReset />
              <GoogleAnalytics />
              {/* <CookiesNotification /> */}
              {renderRoutes(routes)}
            </Router>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </AppDataContext.Provider>
    </StoreProvider>
  );
};

export default App;
