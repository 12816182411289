import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  Card,
  Image,
  Box,
  Paper,
  Button,
  Container,
  CircularProgress,
  Divider,
  useMediaQuery
} from '@material-ui/core';
import { Page } from 'components';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import image1 from '../../assets/scss/paytm.jpg';
import { Link as RouterLink } from 'react-router-dom';
import PrintIcon from '@material-ui/icons/Print';
import AsyncLocalStorage from '@createnextapp/async-local-storage';
import moment from 'moment';
import AppDataContext from 'Helper/AppDataContext';
import { useRef } from 'react';
import { $CombinedState } from 'redux';
import { useEffect } from 'react';
import { styled } from '@material-ui/styles';
import Api from '../../Helper/ApiHandler';
import { async } from 'validate.js';
import axios from 'axios';
import { get } from 'lodash';
import { saveAs } from 'file-saver';
import gpay from '../../assets/scss/googlepay.png';
import phonepay from '../../assets/scss/phonepay.png';
import paytmlogo from '../../assets/scss/paytm.png';
import bhimupi from '../../assets/scss/bhim.png';
import upi from '../../assets/scss/upi.png';
import * as htmlToImage from 'html-to-image';
import ReactToPrint from 'react-to-print';
import { Stack } from '@mui/material';
var api = new Api();

const useStyles = makeStyles(theme => ({
  root: {},
  root: {
    padding: theme.spacing(3)
  },
  classroot: {
    padding: theme.spacing(0, 2),
    marginTop: '100px'
  },
  font1: {
    fontFamily: 'Poppins',
    fontSize: 20,
    fontWeight: 600,
    paddingBottom: '15px',
    // padding: '30px 0px 20px 24px',
    color: '#14279B',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  text: {
    display: 'flex',
    placeItems: 'center'
  },
  font_code: {
    pointerEvents: 'none',
    fontFamily: 'Poppins',
    fontWeight: 520,
    fontSize: 15,
    paddingTop: 0,
    marginTop: '-35px',
    marginLeft: '-30px',
    justifyContent: 'center',
    color: '#000000',
    width: '100%'
  },
  MoveButton: {
    background: '#14279B',
    textTransform: 'none',
    padding: '0px 20px'
  },
  buttonicon: {
    marginRight: '5px',
    display: 'none'
  },
  excelimg: {
    padding: '0 5px 3px 0'
  },
  border: {
    padding: '190px 200px',
    marginTop: '10px'
  },
  border_card: {
    padding: '50px 10px 0px 50px',
    margin: '75px 70px 56px auto'
  },
  font3: {
    textAlign: 'center',
    margin: '10px'
  },
  gr_id: {
    padding: '5px 40px'
  },
  gap: {
    marginBottom: '10px'
  },
  send__button: {
    // margin: 'auto',
    // marginTop: '18px',
    padding: theme.spacing(1),
    width: '100%', // Default width for larger screens
    borderRadius: '0px',
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.3s ease-in-out',
    background: '#14279B',
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  send__button1: {
    padding: theme.spacing(0),
    width: '250%',
    borderRadius: '8px',
    marginRight: 'auto',
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '16px',
    marginLeft: '-140px',
    marginTop: '30px',
    padding: 6,
    transition: '0.3s ease-in-out',
    background: '#14279B',
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '50%'
    }
  },
  font4: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: 'Poppins',
    lineHeight: 2.5
  },
  font4_span: {
    fontSize: 16,
    fontWeight: 300,
    fontFamily: 'Poppins'
  },
  myQr: {
    padding: '24px',
    width: '100%'
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '15px'
  },
  listing: {
    padding: 20,
    borderTop: '1px solid #BFBFBF',
    borderBottom: '1px solid #BFBFBF'
  },
  text_Border: {
    alignItems: 'center'
  },
  response_text: {
    color: 'black',
    fontWeight: 500
  },
  textBorder: {
    borderTop: '1px solid #BFBFBF',
    alignItems: 'center'
  },
  font2: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 650,
    marginLeft: '212px'
  },
  font6: {
    marginLeft: '-29px',
    paddingTop: '30px',
    color: '#696969'
  },
  font7: {
    marginLeft: '427px',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '27px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: '#000000',
    marginTop: '-23px'
  },
  font9: {
    marginLeft: '480px',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '27px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: '#000000',
    marginTop: '-24px'
  },
  font8: {
    marginLeft: '-29px',
    color: '#696969'
  },
  printableArea: {
    padding: theme.spacing(2)
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '14px',
    padding: theme.spacing(2)
  },
  info_heading: {
    fontFamily: 'Poppins',
    margin: '0px 0 20px 0',
    // marginLeft: '10px',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18
    // textAlign: 'center',
  },
  qrCode: {
    margin: theme.spacing(2),
    display: 'contents',
    marginRight: '30px',
    marginTop: '30px'
  },
  logo1: {
    width: '55px',
    marginRight: '180px',
    marginTop: '-20px'
  },
  logo2: {
    width: '65px',
    height: 'auto',
    marginTop: '-23px',
    marginRight: '30px'
  },
  logo3: {
    width: '120px',
    height: 'auto',
    marginTop: '-50px',
    marginRight: '-160px'
  }
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

const GeneratedQR = props => {
  const classes = useStyles();
  const { appContext } = useContext(AppDataContext);
  const [qrCodeUrl, setQRCodeUrl] = useState();
  const [businessName, setBusinessName] = useState();
  let componentRef = useRef();

  const handlePrintClick = () => {
    const printableContent = document.getElementById('qrcode');

    if (printableContent) {
      const printWindow = window.open('', '', 'height=800,width=600');
      printWindow.document.write(
        '<html><head><title>Print</title></head><body>'
      );
      printWindow.document.write(
        '<style>.root { page-break-inside: avoid;} </style>'
      ); // Add CSS to avoid page breaks
      printWindow.document.write(printableContent.innerHTML);
      printWindow.document.write('</body></html>');
      printWindow.document.close();

      printWindow.onload = () => {
        printWindow.print();
        printWindow.close();
      };
    } else {
      console.error("Element with ID 'qrcode' not found.");
    }
  };

  useEffect(() => {
    const generateQRCode = async () => {
      let businessid = await AsyncLocalStorage.getItem('businessid');
      console.log('businessid', businessid);
      api
        .get(`business/generate/qr-code/${businessid}`)
        .then(result => {
          if (result.success === true) {
            setQRCodeUrl(result.data.qr_code_path);
            setBusinessName(result.data.business_name);
          } else {
            console.log(result.data, 'error');
          }
        })
        .catch(err => {
          console.log(err, 'error catch');
        });
    };
    generateQRCode();
  }, []);

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const imageUrl = { qrCodeUrl };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = imageUrl.qrCodeUrl;
    link.target = '_blank';
    link.download = 'image.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleDownload2 = () => {
    const link = document.createElement('a');
    link.href = imageUrl.qrCodeUrl;
    link.download = 'image.jpg';
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {/* <Page className={classes.root} title="My QR" qrCodeUrl={qrCodeUrl}> */}
      <Grid container className={classes.myQr}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Typography variant="h3" className={classes.info_heading}>
            Generated QR Code
          </Typography>
        </Grid>
        {qrCodeUrl ? (
          <>
            <Card variant="outlined" style={{ width: '100%' }}>
              <Container maxWidth="lg" style={{ marginTop: '20px' }}>
                <Grid container spacing={3}>
                  {/* <Grid item xs={12} md={6} lg={4} ref={(el) => (componentRef = el)}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div className={classes.qrCode}>
                          <img src={qrCodeUrl} alt="QR Code" style={{ width: '100%', height: 'auto', maxWidth: '150px' }} />
                        </div>
                        <div>
                          <Typography variant="h6">QR CODE DETAILS</Typography>
                          <Typography variant="h6">Business Name <span>{businessName}</span></Typography>
                          <Typography variant="h6">Created: <span>08 December, 2023</span></Typography>
                        </div>
                      </Box>
                    </Grid> */}
                  <Grid
                    container
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid
                      container
                      ref={el => (componentRef = el)}
                      divider={<Divider orientation="vertical" flexItem />}
                      rowSpacing={1}
                      xs={12}
                      sm={9}
                      md={9}>
                      <Grid xs={12} sm={6} md={6} className={classes.grid}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: isSmallScreen ? 'column' : 'row',
                            alignItems: 'center'
                          }}>
                          <div className={classes.qrCode}>
                            <img
                              src={qrCodeUrl}
                              alt="QR Code"
                              style={{
                                width: '100%',
                                height: 'auto',
                                maxWidth: '150px'
                              }}
                            />
                          </div>
                          <div
                            style={{
                              marginLeft: isSmallScreen ? '0' : '20px'
                            }}>
                            <Typography
                              variant="h6"
                              className={classes.response_text}>
                              QR CODE DETAILS
                            </Typography>
                            <Typography variant="h6">
                              Business Name:{' '}
                              <span className={classes.response_text}>
                                {businessName}
                              </span>
                            </Typography>
                            {/* <Typography variant="h6">
                              Created:{' '}
                              <span className={classes.response_text}>
                                08 December, 2023
                              </span>
                            </Typography> */}
                          </div>
                        </Box>
                      </Grid>
                      {/* <Grid xs={12} sm={6} md={6} className={classes.grid}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              justifyContent: 'center',
                              alignItems: 'center',
                              '& > *': {
                                flex: '0 0 40%',
                                maxWidth: '300px',
                                margin: '10px'
                              }
                            }}>
                            <img
                              src={bhimupi}
                              alt="Google Pay Logo"
                              style={{
                                width: '100%',
                                height: 'auto',
                                maxWidth: '130px'
                              }}
                            />
                            <img
                              src={upi}
                              alt="Google Pay"
                              style={{
                                width: '100%',
                                height: 'auto',
                                maxWidth: '130px'
                              }}
                            />
                            <img
                              src={gpay}
                              alt="Google Pay"
                              style={{
                                width: '100%',
                                height: 'auto',
                                maxWidth: '130px'
                              }}
                            />
                            <img
                              src={paytmlogo}
                              alt="Paytm"
                              style={{
                                width: '100%',
                                height: 'auto',
                                maxWidth: '130px'
                              }}
                            />
                            <img
                              src={phonepay}
                              alt="PhonePe"
                              style={{
                                width: '100%',
                                height: 'auto',
                                maxWidth: '130px'
                              }}
                            />
                          </Box>
                        </Grid> */}
                    </Grid>

                    {/* <Divider orientation="vertical" variant="middle" flexItem /> */}

                    <Grid item xs={12} sm={3} md={3} className={classes.grid}>
                      <div>
                        <Button
                          id="downloadButton"
                          variant="contained"
                          color="primary"
                          className={`${classes.send__button} ${classes.gap}`}
                          // component={RouterLink}
                          onClick={handleDownload}>
                          <GetAppRoundedIcon className={classes.buttonicon} />
                          DOWNLOAD QR CODE
                        </Button>
                        <ReactToPrint
                          trigger={() => (
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.send__button}
                              // component={RouterLink}
                              onClick={handlePrintClick}>
                              <PrintIcon className={classes.buttonicon} />
                              PRINT
                            </Button>
                          )}
                          content={() => componentRef}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  {/* <Grid item row xs={12} md={6} lg={5}>
                      <Grid item xs={12} md={5} lg={6} ref={(el) => (componentRef = el)}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: isSmallScreen ? 'column' : 'row',
                            alignItems: 'center',
                          }}
                        >
                          <div className={classes.qrCode}>
                            <img src={qrCodeUrl} alt="QR Code" style={{ width: '100%', height: 'auto', maxWidth: '150px' }} />
                          </div>
                          <div sx={{ marginLeft: isSmallScreen ? '0' : '20px' }}>
                            <Typography variant="h6">QR CODE DETAILS</Typography>
                            <Typography variant="h6">
                              Business Name <span>{businessName}</span>
                            </Typography>
                            <Typography variant="h6">
                              Created: <span>08 December, 2023</span>
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                      <Divider orientation="vertical" variant="middle" flexItem />
                      <Grid item xs={12} md={5} lg={5}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '& > *': {
                              flex: '0 0 40%',
                              maxWidth: '300px',
                              margin: '10px',
                            },
                          }}
                        >
                          <img src={bhimupi} alt="Google Pay Logo" style={{ width: '100%', height: 'auto', maxWidth: '130px' }} />
                          <img src={upi} alt="Google Pay" style={{ width: '100%', height: 'auto', maxWidth: '130px' }} />
                          <img src={gpay} alt="Google Pay" style={{ width: '100%', height: 'auto', maxWidth: '130px' }} />
                          <img src={paytmlogo} alt="Paytm" style={{ width: '100%', height: 'auto', maxWidth: '130px' }} />
                          <img src={phonepay} alt="PhonePe" style={{ width: '100%', height: 'auto', maxWidth: '130px' }} />
                        </Box>
                      </Grid>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Grid item xs={12} md={6} lg={4}>
                      <ReactToPrint
                        trigger={() => <Button
                          variant="contained"
                          color="primary"
                          className={classes.send__button}
                          component={RouterLink}
                          onClick={handlePrintClick}
                        >
                          <PrintIcon className={classes.buttonicon} />
                          PRINT
                        </Button>}
                        content={() => componentRef}
                      />
                      <Button
                        id="downloadButton"
                        variant="contained"
                        color="primary"
                        className={classes.send__button}
                        component={RouterLink}
                        onClick={handleDownload}
                      >
                        <GetAppRoundedIcon className={classes.buttonicon} />
                        DOWNLOAD QR CODE
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <Paper className={classes.root} elevation={2}>
                        <Grid container spacing={2} justifyContent="flex-end">

                          <Grid item xs={6} sm={3} md={2} lg={2}>
                            <ReactToPrint
                              trigger={() => <Button
                                variant="contained"
                                color="primary"
                                className={classes.send__button}
                                component={RouterLink}
                                onClick={handlePrintClick}
                              >
                                <PrintIcon className={classes.buttonicon} />
                                PRINT
                              </Button>}
                              content={() => componentRef}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3} md={2} lg={2}>
                            <Button
                              id="downloadButton"
                              variant="contained"
                              color="primary"
                              className={classes.send__button1}
                              component={RouterLink}
                              onClick={handleDownload}
                            >
                              <GetAppRoundedIcon className={classes.buttonicon} />
                              DOWNLOAD QR CODE
                            </Button>
                          </Grid>

                          <Card className={classes.border_card}>
                            <Typography variant="h6" className={classes.font_code}>
                              QR CODE DETAILS
                            </Typography>
                            <Typography variant="h6" className={classes.font6}>
                              Bussiness Name
                            </Typography>
                            <Typography variant="h6" className={classes.font7}>
                              {businessName}
                            </Typography>
                          </Card>
                        </Grid>
                      </Paper>
                    </Grid> */}
                </Grid>
              </Container>
            </Card>
          </>
        ) : (
          // <Grid container className={classes.myQr}>
          <Card variant="outlined" style={{ width: '100%' }}>
            <Grid container style={{ justifyContent: 'center' }}>
              <CircularProgress size={30} />
            </Grid>
          </Card>
          // </Grid>
        )}
      </Grid>
      {/* </Page> */}
    </>
  );
};
export default GeneratedQR;
