import React, { Suspense, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';

import { NavBar, TopBar, ChatBar } from './components';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  topBar: {
    zIndex: 2,
    position: 'relative'
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  navBar: {
    zIndex: 3,
    width: 256,
    minWidth: 256,
    flex: '0 0 auto'
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto'
  },
  footer: {
    color: 'black',
    width: '100%',
    position: 'fixed'
  },
  navbar: {
    fontFamily: 'poppins',
    textAlign: 'center',
    color: 'white',
    position: 'absolute fixed',
    padding: '10px',
    width: '100%',
    zIndex: '1000  '
  },
  span: {
    color: '#14279B',
    borderBottom: '0.5px solid #14279B'
  }
}));

const Dashboard = props => {
  const { route } = props;

  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  return (
    <div className={classes.root}>
      <TopBar
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
      <div className={classes.container}>
        <NavBar
          className={classes.navBar}
          onMobileClose={handleNavBarMobileClose}
          openMobile={openNavBarMobile}
        />
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </main>
      </div>
      <ChatBar />
      {/* <nav className={classes.navbar}>
        <div>
          <Grid container>
            <Grid item xs={12} lg={4} md={4} sm={6}>
              <div className={classes.profile}>
                <div>
                  <Box
                    className={classes.avatar}
                    component="img"
                    src={Sequrity}
                    alt=""
                  />
                </div>
                <div>
                  <Typography className={classes.name} variant="h5">
                    100% Safe & Free
                  </Typography>
                  <div>
                    <Typography variant="body2">
                      Pocket Payis safe and toally free
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={4} md={4} sm={6}>
              <div className={classes.profile}>
                <div>
                  <Box
                    className={classes.avatar}
                    component="img"
                    src={Cloud}
                    alt=""
                  />
                </div>
                <div>
                  <Typography className={classes.name} variant="h5">
                    100% auto Data Backup
                  </Typography>
                  <div>
                    <Typography variant="body2">
                      All data is linked to your phone number
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <Typography>
          Copyright 2021 © Lorem Ipsum is{' '}
          <Box component="span" className={classes.span}>
            simply
          </Box>
          dummy
        </Typography>
      </nav> */}
    </div>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
