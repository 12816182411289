const initialState = {
    currentBalance: 100
};

const balanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_BALANCE':
            return {
                ...state,
                currentBalance: action.payload,
            };
        default:
            return state;
    }
};

export default balanceReducer;