import React from 'react';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import PeopleAltOutlined from '@material-ui/icons/PeopleAltOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ChecklistRtlOutlined from '@mui/icons-material/ChecklistRtlOutlined';
import ReceiptOutlined from '@material-ui/icons/ReceiptOutlined';
import AccountBalanceWalletOutlined from '@material-ui/icons/AccountBalanceWalletOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import ManageAccountsOutlined from '@mui/icons-material/ManageAccountsOutlined';
import ShoppingCartOutlined from '@material-ui/icons/ShoppingCartOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import LocalMallOutlined from '@material-ui/icons/LocalMallOutlined';
import LinkOutlined from '@material-ui/icons/LinkOutlined';
import SellOutlined from '@mui/icons-material/SellOutlined';
import SummarizeOutlined from '@mui/icons-material/SummarizeOutlined';
import PermContactCalendarOutlined from '@material-ui/icons/PermContactCalendarOutlined';
import SmsOutlined from '@material-ui/icons/SmsOutlined';
import HandymanOutlined from '@mui/icons-material/HandymanOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import PaymentIcon from '@material-ui/icons/Payment';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { QuizOutlined, ReviewsOutlined } from '@mui/icons-material';
import BedroomParentOutlinedIcon from '@mui/icons-material/BedroomParentOutlined';

const iconImage = require('../../../../assets/scss/icon/sidenav/Overview.svg');
const iconStyle = {
  // width: '100%',
  fill: 'red'
};
const helloicon = () => (
  <>
    {/* <Box
      component="img"
      sx={{
        height: 233,
        width: 350,
        maxHeight: { xs: 233, md: 167 },
        maxWidth: { xs: 350, md: 250 },
      }}
      alt="The house from the offer."
      src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2"
    /> */}
    <img
      // style={{ width: "100%" }}
      alt="helloicon"
      src={iconImage}
      style={iconStyle}
      // src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2"
    />
  </>
);

const businessMasterId = parseInt(localStorage.getItem('business_master_id'));
console.log('businessMasterId', businessMasterId);

let filteredRoutes = [
  {
    // title: 'MANAGE APPOINTMENTS',
    pages: [
      {
        title: 'Dashboard',
        href: '/PocketPay/Dashboard',
        icon: DashboardOutlinedIcon
      }
    ]
  },
  {
    title: 'Business',
    pages: [
      // {
      //   title: 'Dashboard',
      //   href: '/Dashboard',
      //   icon: PollOutlinedIcon,
      // },
      {
        title: 'Business Profile',
        href: '/PocketPay/BusinessProfile',
        icon: BusinessCenterOutlinedIcon
      }
    ]
  },
  {
    title: 'Pages',
    pages: [
      // {
      //   title: 'Overview',
      //   href: '/overview',
      //   icon: HomeIcon
      // },
      // {
      //   title: 'Dashboards',
      //   href: '/dashboards',
      //   // icon: DashboardIcon,
      //   children: [
      //     // {
      //     //   title: 'Default',
      //     //   href: '/dashboards/default'
      //     // },
      //     {
      //       title: 'Analytics',
      //       href: '/dashboards/analytics'
      //     }
      //   ]
      // },
      // {
      //   title: 'Management',
      //   href: '/management',
      //   children: [
      //     {
      //       title: 'Customers',
      //       href: '/management/customers'
      //     },
      //     {
      //       title: 'Customer Details',
      //       href: '/management/customers/1/summary'
      //     },
      //     {
      //       title: 'Projects',
      //       href: '/management/projects'
      //     },
      //     {
      //       title: 'Orders',
      //       href: '/management/orders'
      //     },
      //     {
      //       title: 'Order Details',
      //       href: '/management/orders/1'
      //     }
      //   ]
      // },
      {
        title: 'Pocket Pay',
        href: '/MyQR',
        icon: PaymentIcon,
        children: [
          {
            title: 'Dashboard',
            href: '/PocketPay/Dashboard'
          },
          // {
          //   title: 'Settings',
          //   href: '/Setting',
          //   children: [
          //     {
          //       title: 'Manage KYC',
          //       href: '/PocketPay/Setting/ManageKYC'
          //     },
          //     {
          //       title: 'My QR Code',
          //       href: '/PocketPay/Setting/MyQR'
          //     }
          //   ]
          // },
          {
            title: 'Transactions',
            href: '/PocketPay/Transactions'
          },
          {
            title: 'Withdrawal',
            href: '/PocketPay/Withdrawal'
          },
          {
            title: 'Appointments',
            href: '/PocketPay/Appointments'
          }
          // {
          //   // title: 'Managekyccomplete',
          //   href: '/PocketPay/Managekycdetail'
          // },
        ]
      },
      {
        title: 'Staff Management',
        href: '/staffmanagement',
        icon: AccountBalanceWalletIcon,
        children: [
          {
            title: 'Staff',
            href: '/staffmanagement/staff'
          },
          {
            title: 'Attendance',
            href: '/staffmanagement/staffattendance'
          }
          // {
          //   title: 'Settings',
          //   href: '/staffmanagement/setting'
          // }
        ]
      },
      {
        title: 'Sales and Purchase',
        href: '/sales&purchase',
        icon: ReceiptIcon,
        children: [
          {
            title: 'Dashboard',
            href: '/sales&purchase/dashboard'
          },
          {
            title: 'Manage Party',
            href: '/sales&purchase/manage_party'
          },
          {
            title: 'Product Management',
            href: '/sales&purchase/product_management'
          },
          {
            title: 'Sale',
            href: '/sales&purchase/sale',
            children: [
              {
                title: 'Sale Invoices',
                href: '/sales&purchase/Sale/sale_invoices'
              },
              {
                title: 'Payment In',
                href: '/sales&purchase/Sale/payment_in'
              }
            ]
          },
          {
            title: 'Purchase',
            href: '/sales&purchase/purchase',
            children: [
              {
                title: 'Purchase Bills',
                href: '/sales&purchase/purchase/purchase_bills'
              },
              {
                title: 'Payment Out',
                href: '/sales&purchase/purchase/payment_out'
              }
            ]
          },

          {
            title: 'Payment links and send ',
            href: '/sales&purchase/payment_links_and_send'
          },
          {
            title: 'Reports',
            href: '/sales&purchase/reports',
            children: [
              {
                title: 'Sales',
                href: '/sales&purchase/reports/sales'
              },
              {
                title: 'Purchase',
                href: '/sales&purchase/reports/purchase'
              },
              {
                title: 'Profit & Loss',
                href: '/sales&purchase/reports/profit&loss'
              },
              {
                title: 'Party wise Profit & Loss',
                href: '/sales&purchase/reports/party_wise_profit&loss'
              }
            ]
          },
          {
            title: 'Settings',
            href: '/sales&purchase/settings'
          }
        ]
      },
      {
        title: 'Communication',
        href: '/Communication',
        icon: ChatIcon,
        children: [
          {
            title: 'Dashboard',
            href: '/Communication/dashboard'
          },
          {
            title: 'Contact Management',
            href: '/Communication/contact-management'
          },
          {
            title: 'SMS',
            href: '/Communication/sms',
            children: [
              {
                title: 'Compose SMS',
                href: '/Communication/sms/compose-sms'
              },
              {
                title: 'Bulk SMS',
                href: '/Communication/sms/bulk-sms'
              },
              {
                title: 'Report',
                href: '/Communication/sms/report'
              }
            ]
          }
          // {
          //   title: 'Manage',
          //   href: '/Communication/manage',
          //   children: [
          //     {
          //       title: 'Settings',
          //       href: '/Communication/manage/settings'
          //     },
          //     {
          //       title: 'Notification',
          //       href: '/Communication/manage/notification'
          //     }
          //   ]
          // }
        ]
      }

      // {
      //   title: 'Kanban Board',
      //   href: '/kanban-board',
      //   icon: ListAltIcon
      // },
      // {
      //   title: 'Mail',
      //   href: '/mail',
      //   icon: MailIcon,
      //   label: () => (
      //     <Label
      //       color={colors.red[500]}
      //       shape="rounded"
      //     >
      //       2
      //     </Label>
      //   )
      // },
      // {
      //   title: 'Chat',
      //   href: '/chat',
      //   icon: ChatIcon,
      //   label: () => (
      //     <Label
      //       color={colors.red[500]}
      //       shape="rounded"
      //     >
      //       4
      //     </Label>
      //   )
      // },
      // {
      //   title: 'Calendar',
      //   href: '/calendar',
      //   icon: CalendarTodayIcon,
      //   label: () => <Label color={colors.green[500]}>New</Label>
      // },
      // {
      //   title: 'Settings',
      //   href: '/settings',
      //   icon: SettingsIcon,
      //   children: [
      //     {
      //       title: 'General',
      //       href: '/settings/general'
      //     },
      //     {
      //       title: 'Subscription',
      //       href: '/settings/subscription'
      //     },
      //     {
      //       title: 'Notifications',
      //       href: '/settings/notifications'
      //     },
      //     {
      //       title: 'Security',
      //       href: '/settings/security'
      //     }
      //   ]
      // },
      // {
      //   title: 'Authentication',
      //   href: '/auth',
      //   icon: LockOpenIcon,
      //   children: [
      //     {
      //       title: 'Login',
      //       href: '/auth/login'
      //     },
      //     {
      //       title: 'Register',
      //       href: '/auth/register'
      //     }
      //   ]
      // },
      // {
      //   title: 'Errors',
      //   href: '/errors',
      //   icon: ErrorIcon,
      //   children: [
      //     {
      //       title: 'Error 401',
      //       href: '/errors/error-401'
      //     },
      //     {
      //       title: 'Error 404',
      //       href: '/errors/error-404'
      //     },
      //     {
      //       title: 'Error 500',
      //       href: '/errors/error-500'
      //     }
      //   ]
      // }
    ],
    title: 'Pocket Pay',
    pages: [
      // {
      //   title: 'Overview',
      //   href: '/PocketPay/Dashboard',
      //   icon: DashboardOutlinedIcon,
      //   // icon: <img src='../../../../assets/scss/icon/sideNav/Overtime.svg' alt="OvertimeIcon" />,
      //   // icon: OvertimeIcon,
      // },
      // {
      //   title: 'KYC',
      //   href: '/Setting',
      //   icon: SettingsOutlinedIcon,
      //   children: [
      //     {
      //       title: 'Manage KYC',
      //       href: '/PocketPay/Setting/ManageKYC'
      //     },
      //     {
      //       title: 'My QR Code',
      //       href: '/PocketPay/Setting/MyQR'
      //     }
      //   ]
      // },
      {
        title: 'Transactions',
        href: '/PocketPay/Transactions',
        icon: ReceiptOutlined
      },
      {
        title: 'Withdrawal',
        href: '/PocketPay/Withdrawal',
        icon: AccountBalanceWalletOutlined
      }
    ]
  },
  {
    title: 'MANAGE APPOINTMENTS',
    pages: [
      // {
      //   title: 'Services',
      //   href: '/PocketPay/Services',
      //   icon: DashboardOutlinedIcon,
      // },
      // {
      //   title: 'Appointments',
      //   href: '/PocketPay/Appointments',
      //   icon: TodayOutlinedIcon
      // },

      ...(businessMasterId === 147
        ? []
        : [
            {
              title: 'Appointments',
              href: '/PocketPay/Appointments',
              icon: TodayOutlinedIcon
            }
          ]),
      {
        title: 'Reviews',
        href: '/PocketPay/reviews',
        icon: ReviewsOutlined
      },
      // {
      //   title: 'Inquiries',
      //   href: '/PocketPay/inquiries',
      //   icon: QuizOutlined
      // // },
      // ...(businessMasterId === 147
      //   ? []
      //   : [
      {
        title: 'Inquiries',
        href: '/PocketPay/inquiries',
        icon: QuizOutlined
      },
      // ]),
      ...(businessMasterId === 147
        ? [
            {
              title: 'Hotel Management',
              href: '/PocketPay/hotel_management',
              icon: BedroomParentOutlinedIcon
            }
          ]
        : []),
        {
          title: 'Membership Plans',
          href: '/PocketPay/Membership_Plans',
          icon: ReviewsOutlined
        },
    ]
  },
  {
    title: 'STAFF MANAGEMENT',
    pages: [
      {
        title: 'Staff',
        icon: PeopleAltOutlined,
        href: '/staffmanagement/staff'
      },
      {
        title: 'Attendance',
        icon: ChecklistRtlOutlined,
        href: '/staffmanagement/staffattendance'
      }
      // {
      //   title: 'Settings',
      //   icon: SettingsOutlinedIcon,
      //   href: '/staffmanagement/setting'
      // },
    ]
  },
  {
    title: 'Sales and Purchase',
    pages: [
      {
        title: 'Overview',
        icon: DashboardOutlinedIcon,
        href: '/sales&purchase/dashboard'
      },
      {
        title: 'Manage Party',
        icon: ManageAccountsOutlined,
        href: '/sales&purchase/manage_party'
      },
      {
        title: 'Product Management',
        icon: ShoppingCartOutlined,
        href: '/sales&purchase/product_management'
      },
      {
        title: 'Sale',
        icon: SellOutlined,
        href: '/sales&purchase/sale',
        children: [
          {
            title: 'Sale Invoices',
            href: '/sales&purchase/Sale/sale_invoices'
          },
          {
            title: 'Payment In',
            href: '/sales&purchase/Sale/payment_in'
          }
        ]
      },
      {
        title: 'Purchase',
        icon: LocalMallOutlined,
        href: '/sales&purchase/purchase',
        children: [
          {
            title: 'Purchase Bills',
            href: '/sales&purchase/purchase/purchase_bills'
          },
          {
            title: 'Payment Out',
            href: '/sales&purchase/purchase/payment_out'
          }
        ]
      },

      {
        title: 'Payment links and send ',
        icon: LinkOutlined,
        href: '/sales&purchase/payment_links_and_send'
      },
      {
        title: 'Reports',
        icon: SummarizeOutlined,
        href: '/sales&purchase/reports',
        children: [
          {
            title: 'Sales',
            href: '/sales&purchase/reports/sales'
          },
          {
            title: 'Purchase',
            href: '/sales&purchase/reports/purchase'
          },
          {
            title: 'Profit & Loss',
            href: '/sales&purchase/reports/profit&loss'
          },
          {
            title: 'Party wise Profit & Loss',
            href: '/sales&purchase/reports/party_wise_profit&loss'
          }
        ]
      }
      // {
      //   title: 'Settings', icon: SettingsOutlinedIcon,
      //   href: '/sales&purchase/settings'
      // },
    ]
  },
  {
    title: 'Communication',
    pages: [
      {
        title: 'Overview',
        icon: SettingsOutlinedIcon,
        href: '/Communication/dashboard'
      },
      {
        title: 'Contact Management',
        icon: PermContactCalendarOutlined,
        href: '/Communication/contact-management'
      },
      {
        title: 'SMS',
        icon: SmsOutlined,
        href: '/Communication/sms',
        children: [
          {
            title: 'Compose SMS',
            href: '/Communication/sms/compose-sms'
          },
          {
            title: 'Bulk SMS',
            href: '/Communication/sms/bulk-sms'
          },
          {
            title: 'Report',
            href: '/Communication/sms/report'
          }
        ]
      }
      // {
      //   title: 'Manage', icon: HandymanOutlined,
      //   href: '/Communication/manage',
      //   children: [
      //     {
      //       title: 'Settings',
      //       href: '/Communication/manage/settings'
      //     },
      //     {
      //       title: 'Notification',
      //       href: '/Communication/manage/notification'
      //     }
      //   ]
      // },
    ]
  },
  {
    // title: 'Communication',
    pages: [
      {
        title: 'Subscription',
        icon: LocalAtmIcon,
        href: '/Subscription'
      }
    ]
  }
  // {
  //   title: 'Support',
  //   pages: [
  //     {
  //       title: 'Presentation',
  //       href: '/presentation',
  //       icon: PresentToAllIcon
  //     },
  //     {
  //       title: 'Getting started',
  //       href: '/getting-started',
  //       icon: CodeIcon
  //     },
  //     {
  //       title: 'Changelog',
  //       href: '/changelog',
  //       icon: ViewModuleIcon,
  //       label: () => <Label color={colors.blue['500']}>v1.2.0</Label>
  //     }
  //   ]
  // }
];

// if (businessMasterId === 5) {
//   filteredRoutes = filteredRoutes.filter(route => route.title !== 'MANAGE APPOINTMENTS');
// }

export default filteredRoutes;
