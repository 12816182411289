import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useRouter from 'utils/useRouter';

// Example of user roles: ['GUEST', 'USER', 'ADMIN'];

const AuthGuard = props => {
  const { roles, children } = props;

  const session = useSelector(state => state.session);
  const router = useRouter();

  // const verifytoken = async () => {
  //   console.log('accessTokenDigeesh');
  //   const accessToken = localStorage.getItem('authToken');

  //   console.log('accessToken', accessToken);
  //   // Set up the headers object
  //   const headers = {
  //     Authorization: `Bearer ${accessToken}`
  //   };

  //   api
  //     .post('business/verifyToken', {}, { headers })
  //     .then(result => {
  //       console.log('verifyToken', result);

  //       if (result.success === true) {
  //         // console.log(result.data.status, 'data.status');
  //       } else {
  //         console.log(result, 'error');
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err, 'error catch');
  //     });
  // };

  useEffect(() => {
    // verifytoken();
    console.log('session', session);
    if (session.loggedIn && session.user) {
      router.history.push('/dashboard');
      return;
    }

    if (!session.loggedIn || !session.user) {
      router.history.push('/auth/login');
      return;
    }

    if (!roles.includes(session.user.role)) {
      router.history.push('/errors/error-401');
    }
  }, [router]);

  return <Fragment>{children}</Fragment>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.array.isRequired
};

AuthGuard.defaultProps = {
  roles: []
};

export default AuthGuard;
