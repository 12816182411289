/* eslint-disable react/no-multi-comp */
import React, { useContext, useEffect, useState } from 'react';
import { matchPath } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, Typography } from '@material-ui/core';
import AsyncLocalStorage from '@createnextapp/async-local-storage';
import useRouter from 'utils/useRouter';
import { NavigationListItem } from './components';
import { Chip, Input } from '@mui/joy';
import Api from 'Helper/ApiHandler';
import AppDataContext from 'Helper/AppDataContext';
import { useParams } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    // marginBottom: theme.spacing(3)
    marginBottom: 0
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '5px'
  },
  upgrade_btn: {
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '12px',
    cursor: 'pointer',
    letterSpacing: '0px',
    textAlign: 'right',
    fontFamily: 'Poppins',
    marginBottom: '5px',
    marginRight: '5px',
    padding: '6px 8px 6px 8px',
    borderRadius: '4px',
    background: '#E8E9F5',
    color: '#14279B'
  },
  active_btn: {
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '12px',
    letterSpacing: '0px',
    textAlign: 'right',
    fontFamily: 'Poppins',
    marginBottom: '5px',
    marginLeft: '5px',
    padding: '6px 8px 6px 8px',
    borderRadius: '4px',
    background: '#2BB539',
    color: '#ffff'
  },
  free_btn: {
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '12px',
    letterSpacing: '0px',
    textAlign: 'right',
    fontFamily: 'Poppins',
    marginBottom: '5px',
    marginLeft: '5px',
    padding: '6px 8px 6px 8px',
    borderRadius: '4px',
    background: '#0894EB',
    color: '#ffff'
  },
  expired_btn: {
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '12px',
    letterSpacing: '0px',
    textAlign: 'right',
    fontFamily: 'Poppins',
    marginBottom: '5px',
    marginLeft: '5px',
    padding: '6px 8px 6px 8px',
    borderRadius: '4px',
    background: '#D93737',
    color: '#ffff'
  },
  time_period: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '24px',
    marginRight: '5px',
    letterSpacing: '0em',
    textAlign: 'right',
    color: '#D93737'
  }
}));

const NavigationList = props => {
  const { pages, ...rest } = props;

  return (
    <List>
      {pages.reduce(
        (items, page) => reduceChildRoutes({ items, page, ...rest }),
        []
      )}
    </List>
  );
};

NavigationList.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array
};

const reduceChildRoutes = props => {
  const { router, items, page, depth } = props;

  if (page.children) {
    const open = matchPath(router.location.pathname, {
      path: page.href,
      exact: false
    });

    items.push(
      <NavigationListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        label={page.label}
        open={Boolean(open)}
        title={page.title}>
        <NavigationList
          depth={depth + 1}
          pages={page.children}
          router={router}
        />
      </NavigationListItem>
    );
  } else {
    items.push(
      <NavigationListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        label={page.label}
        title={page.title}
      />
    );
  }

  return items;
};

var api = new Api();

const Navigation = props => {
  const {
    title,
    pages,
    subscription,
    className,
    component: Component,
    ...rest
  } = props;
  // const [subscription, setSubscription] = useState();
  const { appContext } = useContext(AppDataContext);
  const classes = useStyles();
  const router = useRouter();
  const params = useParams();
  const { type } = params;
  const [count, setcount] = useState(0);

  // const getSubscriptiondetails = async () => {
  //   try {
  //     let businessid =
  //       (await AsyncLocalStorage.getItem('businessid')) || appContext.business;

  //     const result = await api.get(
  //       `business/getBusiness?business_id=${businessid}&type=subscription`
  //     );

  //     setSubscription(result.data.business_subscription);
  //     const saveSubscriptionPlanToLocalStorage = async (
  //       planLabel,
  //       storageKey
  //     ) => {
  //       const label = result?.data?.business_subscription?.[planLabel]?.label;
  //       await AsyncLocalStorage.setItem(storageKey, JSON.stringify(label));
  //     };
  //     await saveSubscriptionPlanToLocalStorage(
  //       'manage_appointment',
  //       'manageappointment_plan'
  //     );
  //     await saveSubscriptionPlanToLocalStorage(
  //       'staff_management',
  //       'staffmanagement_plan'
  //     );
  //     await saveSubscriptionPlanToLocalStorage(
  //       'sales_and_purchase',
  //       'salesandpurchase_plan'
  //     );
  //     await saveSubscriptionPlanToLocalStorage(
  //       'communication',
  //       'communication_plan'
  //     );
  //   } catch (err) {
  //     console.error(err, 'error catch');
  //   }
  // };

  // useEffect(() => {
  //   getSubscriptiondetails();
  // }, []);

  const businessMasterId = parseInt(localStorage.getItem('business_master_id'));
  const handleClick = type => {
    router.history.push(`/Subscription/${type}`);
  };

  return (
    <Component {...rest} className={clsx(classes.root, className)}>
      {title && (
        <>
          <div className={classes.flex}>
            <Typography variant="overline">{title}</Typography>
            {title === 'Pocket Pay' && (
              <>
                {subscription?.pocketpay?.is_upgradable === '1' && (
                  <Typography
                    onClick={() => handleClick('pocketpay')}
                    className={classes.upgrade_btn}
                    variant="solid">
                    {subscription?.pocketpay?.upgradable_text}
                  </Typography>
                )}
              </>
            )}

            {businessMasterId !== 5 && (
              <>
                {title === 'MANAGE APPOINTMENTS' && (
                  <>
                    {subscription?.manage_appointment?.is_upgradable ===
                      '1' && (
                      <Typography
                        onClick={() => handleClick('manage_appointment')}
                        className={classes.upgrade_btn}
                        variant="solid">
                        {subscription?.manage_appointment?.upgradable_text}
                      </Typography>
                    )}
                  </>
                )}
              </>
            )}
            {title === 'STAFF MANAGEMENT' && (
              <>
                {subscription?.staff_management?.is_upgradable === '1' && (
                  <Typography
                    onClick={() => handleClick('staff_management')}
                    className={classes.upgrade_btn}
                    variant="solid">
                    {subscription?.staff_management?.upgradable_text}
                  </Typography>
                )}
              </>
            )}
            {title === 'Sales and Purchase' && (
              <>
                {subscription?.sales_and_purchase?.is_upgradable === '1' && (
                  <Typography
                    onClick={() => handleClick('sales_and_purchase')}
                    className={classes.upgrade_btn}
                    variant="solid">
                    {subscription?.sales_and_purchase?.upgradable_text}
                  </Typography>
                )}
              </>
            )}
            {title === 'Communication' && (
              <>
                {subscription?.communication?.is_upgradable === '1' && (
                  <Typography
                    onClick={() => handleClick('communication')}
                    className={classes.upgrade_btn}
                    variant="solid">
                    {subscription?.communication?.upgradable_text}
                  </Typography>
                )}
              </>
            )}
          </div>
          {title === 'Pocket Pay' && (
            <div className={classes.flex}>
              {subscription?.pocketpay?.label && (
                <Typography
                  variant="solid"
                  className={`${
                    subscription?.pocketpay?.label === 'ACTIVE'
                      ? classes.active_btn
                      : subscription?.pocketpay?.label === 'EXPIRED'
                      ? classes.expired_btn
                      : classes.free_btn
                  }`}>
                  {subscription?.pocketpay?.label}
                </Typography>
              )}
              <Typography className={classes.time_period}>
                {subscription?.pocketpay?.text}
              </Typography>
            </div>
          )}
          {title === 'MANAGE APPOINTMENTS' && (
            <div className={classes.flex}>
              {subscription?.manage_appointment?.label && (
                <Typography
                  variant="solid"
                  className={`${
                    subscription?.manage_appointment?.label === 'ACTIVE'
                      ? classes.active_btn
                      : subscription?.manage_appointment?.label === 'EXPIRED'
                      ? classes.expired_btn
                      : classes.expired_btn
                  }`}>
                  {subscription?.manage_appointment?.label}
                </Typography>
              )}
              <Typography className={classes.time_period}>
                {subscription?.manage_appointment?.text}
              </Typography>
            </div>
          )}
          {title === 'STAFF MANAGEMENT' && (
            <div className={classes.flex}>
              {subscription?.staff_management?.label && (
                <Typography
                  variant="solid"
                  className={`${
                    subscription?.staff_management?.label === 'ACTIVE'
                      ? classes.active_btn
                      : subscription?.staff_management?.label === 'EXPIRED'
                      ? classes.expired_btn
                      : classes.expired_btn
                  }`}>
                  {subscription?.staff_management?.label}
                </Typography>
              )}
              <Typography className={classes.time_period}>
                {subscription?.staff_management?.text}
              </Typography>
            </div>
          )}
          {title === 'Sales and Purchase' && (
            <div className={classes.flex}>
              {subscription?.sales_and_purchase?.label && (
                <Typography
                  variant="solid"
                  className={`${
                    subscription?.sales_and_purchase?.label === 'ACTIVE'
                      ? classes.active_btn
                      : subscription?.sales_and_purchase?.label === 'EXPIRED'
                      ? classes.expired_btn
                      : classes.expired_btn
                  }`}>
                  {subscription?.sales_and_purchase?.label}
                </Typography>
              )}
              <Typography className={classes.time_period}>
                {subscription?.sales_and_purchase?.text}
              </Typography>
            </div>
          )}
          {title === 'Communication' && (
            <div className={classes.flex}>
              {subscription?.communication?.label && (
                <Typography
                  variant="solid"
                  className={`${
                    subscription?.communication?.label === 'ACTIVE'
                      ? classes.active_btn
                      : subscription?.communication?.label === 'EXPIRED'
                      ? classes.expired_btn
                      : classes.expired_btn
                  }`}>
                  {subscription?.communication?.label}
                </Typography>
              )}
              <Typography className={classes.time_period}>
                {subscription?.communication?.text}
              </Typography>
            </div>
          )}
        </>
      )}
      <NavigationList depth={0} pages={pages} router={router} />
    </Component>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string
};

Navigation.defaultProps = {
  component: 'nav'
};

export default Navigation;
