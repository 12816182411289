import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Drawer,
  Divider,
  Paper,
  Button,
  MenuItem,
  Menu,
  IconButton,
  Avatar,
  Typography,
  Box
} from '@material-ui/core';
import { Hidden } from '@material-ui/core';
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import useRouter from 'utils/useRouter';
import { Navigation } from 'components';
import navigationConfig from './navigationConfig';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AsyncLocalStorage from '@createnextapp/async-local-storage';
import AddIcon from './../../../../assets/scss/add.svg';
import Api from '../../../../Helper/ApiHandler';
import AppDataContext from 'Helper/AppDataContext';
import { Stack } from '@mui/material';
var api = new Api();

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    fontFamily: 'Poppins',
    '&:focus': {
      color: 'red'
    }
  },
  content: {
    // padding: theme.spacing(2)
    padding: '0px'
  },
  profile: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 'fit-content',
    padding: '0px 15px',
    paddingTop: '10px'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    width: '100%',
    fontFamily: 'Poppins',
    marginTop: '4px',
    fontSize: '12px',
    border: 'none',
    textTransform: 'capitalize',
    justifyContent: 'start'
  },
  menufont: {
    fontFamily: 'Poppins',
    marginTop: '4px',
    fontSize: '14px',
    padding: '4px  25px',
    border: 'none',
    textTransform: 'none',
    color: '#14279B'
  },
  menufont__loop: {
    fontFamily: 'Poppins',
    marginTop: '4px',
    fontSize: '14px',
    padding: '4px  25px',
    border: 'none',
    textTransform: 'capitalize'
  },
  divider: {
    marginBottom: theme.spacing(2)
  },
  divider2: {
    marginTop: theme.spacing(2)
  },
  navigation: {
    marginTop: '0px',
    marginBottom: '0px',
    color: 'red'
  },
  menubtn: {
    marginTop: '38px'
  },
  border: {
    border: 'none'
  },
  box: {
    marginLeft: '40px'
  }
}));

const NavBar = props => {
  const { appContext, setAppContext } = useContext(AppDataContext);
  const { openMobile, onMobileClose, className, ...rest } = props;
  const [user, setUser] = React.useState(null);
  const [userName, setUserName] = React.useState('');
  const [userProfile, setUserProfile] = React.useState('');
  const [userbusinessId, setUserbusinessId] = React.useState('');
  const [masterId, setMasterID] = useState();
  const [subscription, setSubscription] = useState();
  const classes = useStyles();
  const router = useRouter();
  // const session = useSelector(state => state.session);

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
  }, [router.location.pathname]);

  useEffect(() => {
    allData();
    currentBalance();
  }, []);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [data, setData] = useState([]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const allData = async () => {
    try {
      let payload = await api.get('business/getBusiness');
      if (payload.success === true) {
        setData(payload.data);
        let u_id = await AsyncLocalStorage.getItem('businessid');
        let u_name = await AsyncLocalStorage.getItem('businessName');
        let u_profile = await AsyncLocalStorage.getItem('businessLogo');
        // setUser(u_id);
        // setUserName(u_name);
        // setUserbusinessId(u_id)
        if (u_id) {
          setUser(u_id);
          setUserName(u_name);
          setUserProfile(u_profile);
        } else {
          if (payload?.data?.length) {
            setUser(payload.data[0].id);
            setUserName(payload.data[0].business_name);
            AsyncLocalStorage.setItem('businessid', payload.data[0].id);
            setAppContext({ ...appContext, business: payload.data[0].id });
            setUserProfile(payload.data[0].business_logo_path);
            AsyncLocalStorage.setItem(
              'businessName',
              payload.data[0].business_name
            );
            AsyncLocalStorage.setItem(
              'businessLogo',
              payload.data[0].business_logo_path
            );
            currentBalance();
            // window.location.reload();
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const currentBalance = async () => {
    let businessid =
      (await AsyncLocalStorage.getItem('businessid')) || appContext.business;
    if (businessid) {
      api
        .get(`business/business_balance/${businessid}`)
        .then(result => {
          AsyncLocalStorage.setItem('wallet', result?.wallet);
        })
        .catch(err => {
          console.log(err, 'error catch');
        });
    }
  };

  const getSubscriptiondetails = async () => {
    try {
      let businessid =
        (await AsyncLocalStorage.getItem('businessid')) || appContext.business;

      setUserbusinessId(businessid);

      const result = await api.get(
        `business/getBusiness?business_id=${businessid}&type=subscription`
      );

      setMasterID(result.data.business_master_id);
      localStorage.setItem(
        'business_master_id',
        result.data.business_master_id
      );
      setSubscription(result.data.business_subscription);
      const saveSubscriptionPlanToLocalStorage = async (
        planLabel,
        storageKey
      ) => {
        const label = result?.data?.business_subscription?.[planLabel]?.label;
        await AsyncLocalStorage.setItem(storageKey, JSON.stringify(label));
      };
      await saveSubscriptionPlanToLocalStorage(
        'manage_appointment',
        'manageappointment_plan'
      );
      await saveSubscriptionPlanToLocalStorage(
        'staff_management',
        'staffmanagement_plan'
      );
      await saveSubscriptionPlanToLocalStorage(
        'sales_and_purchase',
        'salesandpurchase_plan'
      );
      await saveSubscriptionPlanToLocalStorage(
        'communication',
        'communication_plan'
      );
    } catch (err) {
      console.error(err, 'error catch');
    }
  };
  useEffect(() => {
    getSubscriptiondetails();
  }, [appContext]);

  let displayedName = userName ? userName.substring(0, 20) : '';
  if (userName && userName.length > 20) {
    displayedName += '...';
  }
  const getUserInitials = name => {
    if (!name) return '';
    return name
      .replace(/\s+/, ' ')
      .split(' ')
      .slice(0, 2)
      .map(v => v && v[0].toUpperCase())
      .join('');
  };
  // useEffect(async () => {
  //   let businessid =
  //     (await AsyncLocalStorage.getItem('businessid')) || appContext.business;
  //   setUserbusinessId(businessid);
  //   if (userbusinessId !== undefined) {
  //     setTimeout(() => {
  //       getSubscriptiondetails();
  //     }, 500);
  //   } else {
  //     setTimeout(() => {
  //       getSubscriptiondetails();
  //     }, 4000);
  //   }
  // }, []);

  const history = useHistory();

  // const key_id = 4;

  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile}>
        {/* <img
          alt="Remy Sharp"
          // className={classes.avatar}
          src="https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1331&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          style={{ width: 24, height: 24, borderRadius:3}}
        /> */}
        <div
          style={
            userProfile
              ? {
                  width: 30,
                  height: 25,
                  borderRadius: 3,
                  overflow: 'hidden'
                }
              : {
                  borderRadius: 3
                }
          }>
          {/* <img
            src={userProfile}
            // src="https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1331&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="{`Image ${index + 1}`}"
            style={{
              width: 25,
              height: 'auto',
              objectFit: 'cover'
            }}
          /> */}
          {userProfile ? (
            <>
              <img
                src={userProfile}
                // src="https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1331&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Image"
                style={{
                  width: 25,
                  height: 'auto',
                  objectFit: 'cover'
                }}
              />
            </>
          ) : (
            <>
              {/* <Avatar variant="rounded">{getUserInitials(userName)}</Avatar> */}

              <Avatar>{getUserInitials(userName)}</Avatar>
            </>
          )}
        </div>
        <Button
          fullWidth
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.name}>
          {displayedName}
          <KeyboardArrowDownIcon style={{ marginLeft: '62px' }} />
        </Button>
        {/* <IconButton
          aria-label="delete"
          onClick={() => {
            history.push(`/auth/BasicBusinessDetails`);
          }}
          color="primary"
          title="Add Business">
          <ControlPointRoundedIcon />
          <img src={AddIcon} alt="AddIcon" />
        </IconButton> */}
        <Menu
          className={classes.menubtn}
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{ className: classes.box }}>
          {data.map((obj, ind) => {
            return (
              <MenuItem
                className={classes.menufont__loop}
                key={`obj_${ind}`}
                onClick={() => {
                  handleClose();
                  allData();
                  setUser(obj?.id);
                  setUserName(obj?.business_name);
                  AsyncLocalStorage.setItem('businessid', obj.id);
                  AsyncLocalStorage.setItem('businessName', obj.business_name);
                  AsyncLocalStorage.setItem(
                    'businessLogo',
                    obj.business_logo_path
                  );
                  setAppContext({ ...appContext, business: obj.id });
                  history.push('/PocketPay/Dashboard');
                  // window.location.reload();
                }}
                value={obj?.id}>
                {obj?.business_name}
              </MenuItem>
            );
          })}
          <MenuItem
            className={classes.menufont}
            // onClick={() => { handleAdd() }}
            onClick={() => {
              history.push(`/auth/BasicBusinessDetails`);
            }}>
            Add Business
          </MenuItem>
        </Menu>
      </div>
      <Divider className={classes.divider2} />
      <nav className={classes.navigation}>
        {subscription && (
          <>
            {navigationConfig.map(list => (
              <React.Fragment key={list.title}>
                {(masterId !== 5 || list.title !== 'MANAGE APPOINTMENTS') && (
                  <>
                    <Navigation
                      component="div"
                      subscription={subscription}
                      key={list?.title}
                      pages={list?.pages}
                      title={list?.title}
                    />
                    <Divider className={classes.divider} />
                  </>
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </nav>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary">
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square>
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
