import { combineReducers } from 'redux';

import sessionReducer from './sessionReducer';
import balanceReducer from './balanceReducer';


const rootReducer = combineReducers({
  session: sessionReducer,
  balance: balanceReducer,

});

export default rootReducer;

