import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';

import {
  FormControlLabel,
  Switch,
  Grid,
  Card,
  CardContent,
  Divider,
  Button,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  InputLabel,
  InputAdornment,
  Slide,
  FormControl,
  Select,
  MenuItem,
  Hidden,
  IconButton
} from '@material-ui/core';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import Sequrity from '../../../../assets/scss/icon/Sequrity.svg';
// import Cloud from '../../../../assets/scss/icon/Cloud.svg';
import PersonIcon from '@material-ui/icons/Person';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SettingsIcon from '@material-ui/icons/Settings';
import CloseIcon from '@material-ui/icons/Close';
// import Api from '../../../../Helper/ApiHandler';
import AsyncLocalStorage from '@createnextapp/async-local-storage';
import SignoutPopup from '.';
import Api from '../../Helper/ApiHandler';
import useRouter from 'utils/useRouter';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
var api = new Api();
// var api = new Api();

const useStyles = makeStyles(theme => ({
  font6: {
    fontFamily: 'Poppins',
    color: '#FFFFFF',
    fontWeight: 400,
    fontSize: '18px'
  },
  title: {
    backgroundColor: '#14279B',
    padding: '4px 18px'
  },
  close: {
    marginLeft: '15px',
    color: 'white',
    transition: '0.3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      color: 'black'
    }
  },
  contant: {
    padding: '10px 40px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 40px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 20px'
    }
  },
  dialogbtn: {
    textAlign: 'center'
  },
  continue: {
    borderRadius: '10px',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    width: '90%',
    padding: theme.spacing(1.5),
    justifyContent: 'center',
    textAlign: 'center',
    margin: '25px 0',
    textTransform: 'none',
    background: '#14279B'
  },
  cancel: {
    borderRadius: '10px',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    width: '90%',
    padding: theme.spacing(1.5),
    justifyContent: 'center',
    textAlign: 'center',
    margin: '25px 0',
    textTransform: 'none'
  },
  bIcon: {
    fontSize: '40px',
    color: '#14279B'
  },
  business: {
    display: 'flex'
  },
  bDelete: {
    padding: '12px'
  },
  container: {
    padding: '10px   '
  },
  text: {
    fontFamily: 'Poppins'
  },
  font1: {
    fontFamily: 'Poppins',
    marginTop: '20px'
  },
  logoutButton: {
    marginLeft: theme.spacing(0),
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontWeight: 400
    // marginRight:"1000px"
    // fontSize:14
  },
  icon: {
    // marginLeft: "-100px"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Message(props) {
  const classes = useStyles();
  const router = useRouter();
  const [message, setMessage] = useState(false);
  const {
    open,
    object,
    onUpdate,
    setSessionMessage,
    onOpenNavBarMobile
  } = props;
  // console.log(object, "object")

  const handleLogout = e => {
    // console.log("call")
    // e.preventDefault();
    // setLoginOpen(true);
    // // e.preventdefault()
    api
      .get('logOut')
      .then(result => {
        if (result.success === true) {
          console.log(result, 'success');
          setMessage(false);
          localStorage.clear();

          router.history.push('/');
        } else {
          console.log(result, 'error');
        }
      })
      .catch(err => {
        console.log(err, 'error catch');
      });
  };

  const handleOpenDialog = () => {
    setMessage(true); // Set message to true to show the dialog
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    setMessage(false); // Set message to false to hide the dialog
  };
  return (
    <>
      {/* <Button onClick={handleOpenDialog}>Open Dialog</Button> */}
      <Hidden mdDown>
        <Button
          className={classes.logoutButton}
          color="inherit"
          onClick={handleOpenDialog}>
          <InputIcon style={{ paddingRight: 3, marginRight: 2 }} />
          Sign Out
        </Button>
        {/* <SignoutPopup
          open={loginOpen}
        // setLoginOpen={setLoginOpen}
        // onOverTimeUpdate={onOverTimeUpdate}
        // minWithdraw={minWithdraw}
        /> */}
      </Hidden>
      <Hidden lgUp>
        <IconButton color="inherit" onClick={onOpenNavBarMobile}>
          <MenuIcon />
        </IconButton>
      </Hidden>
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        open={message} // Use the message state to control dialog visibility
        maxWidth={'xs'}
        onClose={handleCloseDialog} // Handle dialog close event
      >
        <DialogTitle className={classes.title}>
          <Grid container>
            <Grid item xs={11}>
              <Typography variant="h3" className={classes.font6}>
                Log Out
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <CloseIcon
                className={classes.close}
                onClick={handleCloseDialog} // Close the dialog when the close icon is clicked
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.contant}>
          <Grid container>
            <Grid item xs={12} className={classes.dialogbtn}>
              <Typography variant="h3" className={classes.font1}>
                Are You Sure you want to Logout?
              </Typography>
            </Grid>
          </Grid>
          {/* ... (dialog content) */}
          <Grid container spacing={2}>
            <Grid item xs={6} className={classes.dialogbtn}>
              <Button
                className={classes.cancel}
                variant="outlined"
                color="primary"
                onClick={handleCloseDialog} // Close the dialog when the "Cancel" button is clicked
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} className={classes.dialogbtn}>
              <Button
                className={classes.continue}
                variant="contained"
                color="primary"
                onClick={() => {
                  handleLogout();
                  handleCloseDialog(); // Close the dialog after logging out
                }}>
                Log Out
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Message;
