import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback
} from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  Card,
  Switch,
  Snackbar,
  colors,
  Button,
  Box,
  Tab,
  CardContent,
  Tabs,
  Divider,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  DialogActions,
  DialogContent,
  Checkbox,
  FormControlLabel,
  DialogContentText,
  TextField,
  InputLabel,
  InputAdornment,
  Drawer,
  SwipeableDrawer,
  Slide,
  Fade
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Page } from 'components';
import razorPayticon from '../../assets/scss/RazorPayticon.png';
import walletIcon from '../../assets/scss/Walleticon.png';
import image2 from '../../assets/scss/tick.svg';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import AsyncLocalStorage from '@createnextapp/async-local-storage';
import GeneratedQR from 'views/GeneratedQR/GeneratedQR';
import Api from '../../Helper/ApiHandler';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { AddBoxTwoTone, ApiOutlined, Close } from '@mui/icons-material';
import AppDataContext from 'Helper/AppDataContext';
import { useParams } from 'react-router';
import Axios from 'axios';
import { formatDiagnosticsWithColorAndContext } from 'typescript';
var api = new Api();

const schema = {
  amount: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 1
      // maximum: 50,
      // message: ' must be in between 2 to 50 character'
    }
  }
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {},
  root: {
    padding: theme.spacing(2)
  },
  classroot: {
    padding: theme.spacing(0, 2)
  },
  backIcon: {
    cursor: 'pointer',
    fontSize: '20px',
    position: 'relative',
    top: '5px',
    color: '#14279B'
  },
  loader: {
    width: '100%',
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  btngroup: {
    textAlign: 'end',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  drawerContent: {
    position: 'absolute',
    bottom: 0,
    // right: 0,
    width: '100%',
    height: 'auto',
    backgroundColor: '#fff',
    boxShadow:
      '1px -20px 20px 0px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
    zIndex: 1300,
    padding: theme.spacing(2),
    transition: 'transform 0.3s ease'
  },
  drawerContent2: {
    position: 'absolute',
    bottom: 0,
    // right: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: 'auto',
    backgroundColor: '#fff',
    boxShadow:
      '1px -20px 20px 0px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
    zIndex: 1300,
    padding: theme.spacing(2),
    transition: 'transform 0.3s ease'
  },
  drawerContentOpen: {
    transform: 'translateX(0)'
  },
  drawerContentClose: {
    transform: 'translateX(100%)'
  },
  font1: {
    fontFamily: 'Poppins',
    fontSize: 20,
    fontWeight: 600,
    paddingBottom: '15px',
    // padding: '30px 0px 20px 24px',
    color: '#14279B',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  font2: {
    fontFamily: 'Poppins',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 20, // Default font size for larger screens
    fontWeight: 400,
    color: '#000000',
    width: '100%',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16 // Adjust font size for small screens (sm) and below
    }
  },
  border_card: {
    padding: 10
  },
  myQr: {
    padding: '60px 40px'
  },
  MoveButton: {
    background: '#14279B',
    textTransform: 'none',
    padding: '0px 20px'
  },

  excelimg: {
    padding: '0 5px 3px 0'
  },
  tabpanel: {
    fontFamily: 'Poppins'
  },
  info_heading: {
    fontFamily: 'Poppins',
    margin: '20px 0 20px 0',
    // marginLeft: '10px',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18
    // textAlign: 'center',
  },
  form_input: {
    width: '100%',
    height: '55px',
    background: '#F0F3FD',
    border: '0.5px solid #E1E1E1',
    borderRadius: '8px',
    fontSize: 22,
    color: '#14279B',
    fontFamily: 'Poppins',
    marginTop: '5px'
  },
  UpgradeNow: {
    marginTop: '30px'
    // backgroundColor: '#F9FAFF',
    // padding: 5
  },
  StartedText: {
    padding: '10px 0px',
    fontWeight: 700
  },
  redText: {
    color: 'red'
  },
  form__input: {
    width: '100%',
    height: '45px',
    border: '0.5px solid #E1E1E1',
    borderRadius: '8px',
    fontSize: 14,
    color: '#14279B',
    fontFamily: 'Poppins',
    marginTop: '5px',
    padding: 10,
    resize: 'none'
  },
  form__input__amount: {
    width: '100%',
    height: '45px',
    border: '0.5px solid #E1E1E1',
    borderRadius: '8px',
    fontSize: 14,
    color: '#14279B',
    fontFamily: 'Poppins',
    marginBottom: '21px',
    // padding: 10,
    resize: 'none'
  },
  forminput: {
    width: '100%',
    padding: 13,
    background: '#F0F3FD',
    border: '0.5px solid #E1E1E1',
    borderRadius: '8px',
    fontSize: 14,
    color: '#14279B',
    fontFamily: 'Poppins',
    marginTop: '5px'
  },
  border: {
    padding: '90px 300px',
    marginTop: '17px',
    justifyContent: 'center'
  },
  dialogUpper: {
    backgroundColor: '#14279b',
    color: 'white',
    // boxShadow:
    //   '-2px -4px 7px 0px rgb(0 0 0 / 0%), 0px 6px 10px 4px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
    marginBottom: '20px'
  },
  font3: {
    textAlign: 'center',
    margin: '10px'
  },
  centerText1: {
    textAlign: 'center',
    fontWeight: 800
  },
  centerText2: {
    textAlign: 'center',
    padding: '15px 0',
    color: '#14279B'
  },
  centerText3: {
    textAlign: 'center',
    display: 'flex',
    fontFamily: 'Poppins',
    alignItems: 'center',
    justifyContent: 'center'
  },
  grid: {
    padding: 40
  },
  spacing: {
    marginTop: '45px',
    marginBottom: '45px'
  },
  spacing2: {
    marginLeft: '5px',
    marginRight: '5px'
  },
  gr_id: {
    padding: '5px 40px'
  },
  send__button: {
    marginLeft: 'auto',
    marginTop: '18px',
    padding: theme.spacing(1),
    width: '32%',
    borderRadius: '0px',
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.3s ease-in-out',
    background: '#14279B',
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  input_label: {
    fontSize: '16px',
    fontWeight: 400,
    marginTop: '20px',
    // marginBottom: '20px',
    color: '#000',
    fontFamily: 'Poppins'
  },
  input_label_amount: {
    fontSize: '16px',
    fontWeight: 400,
    marginTop: '-3px',
    // marginBottom: '20px',
    color: '#000',
    fontFamily: 'Poppins'
  },
  input_label1: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#000',
    fontFamily: 'Poppins'
  },
  font4: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center',
    fontFamily: 'Poppins',
    lineHeight: 2.5
  },
  tab: {
    textTransform: 'none',
    fontWeight: 400,
    fontSize: 16,
    color: '#C6C6C6'
  },
  qrCodeContainer: {
    marginTop: '12px',
    border: '1px solid #eeeeee',
    padding: '5px',
    borderRadius: '10px'
  },
  qrContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  selectedTab: {
    color: '#14279B',
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 16,
    background: '#F9FAFF',
    borderBottom: '4px solid #14279B'
  },
  font4_span: {
    fontSize: 16,
    fontWeight: 300,
    fontFamily: 'Poppins'
  },
  listing: {
    padding: 20,
    borderTop: '1px solid #BFBFBF',
    borderBottom: '1px solid #BFBFBF'
  },
  btnGroup: {
    marginLeft: '195px'
  },

  text_Border: {
    alignItems: 'center'
  },
  textBorder: {
    borderTop: '1px solid #BFBFBF',
    alignItems: 'center'
  },
  font2: {
    fontFamily: 'Poppins',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start',
    fontSize: 20,
    fontWeight: 600,
    marginTop: '8px'
  },
  walletAmount: {
    fontFamily: 'Poppins',
    fontSize: 15,
    fontWeight: 600,
    color: '#000000',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  walletAmount2: {
    fontFamily: 'Poppins',
    marginTop: '10px',
    fontSize: 15,
    fontWeight: 600,
    color: '#000000',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  font6: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 500,
    color: '#000000',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  },
  font7: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 500,
    color: '#000000',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  },
  image: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },

  modal_heading2: {
    fontSize: 22,
    fontWeight: 500
  },
  modal_heading: {
    fontSize: 18,
    color: 'white',
    fontWeight: 500
  },
  closeButton: {
    color: 'white'
  },
  card: {
    // maxWidth: 345,
    margin: 'auto'
  },
  mostPopularCard: {
    border: '1px solid #14279B'
  },
  mostPopularCard2: {
    border: '1px solid #d0d7ff'
  },
  model_button2: {
    background: '#14279B',
    color: 'white',
    '&:hover': {
      background: '#0e1d75'
    }
  },
  mostPopularText: {
    textAlign: 'center',
    color: 'white',
    fontWeight: 600,
    padding: '14px 0',
    backgroundColor: '#14279B',
    marginBottom: '0px'
  },
  mostPopularText2: {
    textAlign: 'center',
    color: '#14279b',
    fontWeight: 500,
    padding: '14px 0',
    backgroundColor: '#d0d7ff',
    marginBottom: '0px'
  },
  cardHeader: {
    padding: theme.spacing(3),
    paddingBottom: '0px',
    textAlign: 'start'
  },
  price: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2)
  },
  priceText: {
    fontSize: '2rem',
    fontWeight: 600,
    marginRight: theme.spacing(1)
  },
  priceUnit: {
    fontSize: '1rem'
  },
  modalContentText: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px'
  },
  modalContentText1: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    background: '#e3e6ff',
    padding: '10px',
    border: 'solid 2px #14279b',
    borderRadius: '5px'
  },
  modalContent: {
    // display: 'flex',
    marginBottom: '20px'
  },
  qrCodeImage: {
    width: '100%',
    height: 'auto'
  },
  bullet: {
    color: theme.palette.primary.main,
    margin: '0px 2px',
    fontSize: '1rem'
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  PaymentDetails: {
    fontSize: 16,
    fontWeight: 600,
    cursor: 'pointer',
    fontFamily: 'Poppins',
    width: '50%',
    border: '1px solid rgba(63, 81, 181, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },

  // qrButton: {
  //   fontSize: 16,
  //   display: 'flex',
  //   fontWeight: 700,
  //   cursor: 'pointer',
  //   fontFamily: 'Poppins',
  //   // width:'50%',
  //   justifyContent: 'center',
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%'
  //   },
  //   marginTop: theme.spacing(2)
  //   // marginBottom: theme.spacing(2)
  // },
  viewText: {
    color: '#AEB0B1',
    fontSize: 14,
    fontWeight: 500,
    cursor: 'pointer',
    fontFamily: 'Poppins',
    justifyContent: 'start'
  },
  qrButton: {
    fontSize: 16,
    display: 'flex',
    fontWeight: 700,
    cursor: 'pointer',
    fontFamily: 'Poppins',
    // width:'50%',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    marginTop: theme.spacing(2)
    // marginBottom: theme.spacing(2)
  },
  model_buttons: {
    padding: '10px 24px 20px 24px'
  },
  model_buttons2: {
    boxShadow:
      '-2px -4px 7px 0px rgb(0 0 0 / 0%), 0px 6px 10px 4px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
    marginTop: '20px',
    padding: '20px 24px 20px 24px'
  },
  listItem: {
    padding: '10px 0',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center'
  },
  text_space: {
    fontWeight: 800,
    fontSize: '16px',
    marginBottom: 3
  },
  actionArea: {
    margin: '25px 0',
    padding: theme.spacing(1),
    width: '100%',
    borderRadius: '4px',
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.3s ease-in-out',
    background: '#14279B',
    textTransform: 'none'
  }
}));

const Addwallet = ({
  id,
  plan_name,
  currentBalance,
  setOpen,
  amount,
  open,
  plan_desc,
  most_popular,
  subscription_type,
  service_type,
  subscription_plan_id,
  subscription_duration,
  key_features
}) => {
  const classes = useStyles();
  const card_id = id;
  const [accountName, setAccountName] = useState();
  const [UPIid, setUPIid] = useState();
  const [ErrorMessage, setErrorMessage] = useState();
  const [showQRCode, setShowQRCode] = useState(false);
  const [merchantTranId, setMerchantTranId] = useState();
  const [QR, setQR] = useState();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerOpen2, setDrawerOpen2] = useState(false);
  const [qrcodeLoader, setQrcodeLoader] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [successView, setSuccessView] = useState(false);
  const [failureView, setFailureView] = useState(false);
  const [timeLeft, setTimeLeft] = useState(11 * 60);
  const { appContext } = useContext(AppDataContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [onlyaccountpay, setOnlyaccountpay] = useState();
  const [onlyaccountpayqrcode, setOnlyaccountpayqrcode] = useState();
  const [responseapi, setResponseapi] = useState({});
  const businessWallet = parseInt(localStorage.getItem('wallet'));
  const [walletAmount, setWalletAmount] = useState(businessWallet); // Initial wallet amount
  const gstAmount = amount * 0.18;
  const [loading, setLoading] = useState(false);
  const [statusapi, setStatusapi] = useState('');
  const [statusqrcode, setStatusqrcode] = useState('');
  const [useWallet, setUseWallet] = useState(false); // State to manage wallet payment checkbox
  const [remainingAmount, setRemainingAmount] = useState(amount + gstAmount);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [sessionMessage, setSessionMessage] = useState({
    message: '',
    type: '',
    open: '',
    vertical: '',
    horizontal: ''
  });
  const { vertical, horizontal } = sessionMessage;
  const handleCloseAlert = () => {
    setSessionMessage({
      message: '',
      type: '',
      open: '',
      vertical: '',
      horizontal: ''
    });
  };

  const apiKey = 'bizz_live_icLdNmBlMkoUtPz';
  const apiSecret = 'myLbnGyqorvoGPftqXcOLVEBev0DO7gG3s0v';

  const handleWalletCheckbox = event => {
    setUseWallet(event.target.checked);
    if (event.target.checked) {
      const remaining = amount + gstAmount - walletAmount;
      if (remaining < 0) {
        setShowQRCode(false);
        setWalletAmount(amount + gstAmount);
        setRemainingAmount(0);
      } else {
        setRemainingAmount(remaining);
      }
    } else {
      setRemainingAmount(amount + gstAmount);
    }
  };

  const handleGetStarted = () => {
    setModalOpen(true);
  };

  // const handleGetStarted2 = () => {
  //   setOpen(true);
  // };
  // const handleGetclose = () => {
  //   setOpen(false);
  // };
  function generateUniqueOrderId() {
    const length = 16;
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  const handleMainDialog = () => {
    setOpen(false);
    setDrawerOpen(false);
    setUseWallet(false);
    setTimeLeft(11 * 60);
    setShowQRCode(false);
  };

  const getSubscriptiondetails = async () => {
    try {
      let businessid =
        (await AsyncLocalStorage.getItem('businessid')) || appContext.business;

      const result = await api.get(
        `business/getBusiness?business_id=${businessid}&type=subscription`
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handlePaybutton = async () => {
    let businessId =
      (await AsyncLocalStorage.getItem('businessid')) || appContext.business;
    try {
      const uniqueOrderId = generateUniqueOrderId();
      setDrawerOpen2(true);

      const res = await Axios.post(
        'https://www.bizzbrains.com/bizzbrain-payment/api/collectpay',
        {
          api_key: apiKey,
          api_secrets: apiSecret,
          note: 'Remark',
          payerVa: UPIid,
          order_id: uniqueOrderId,
          amount: formState.values.amount
        }
      );
      if (res?.data?.status === 200) {
        setMerchantTranId(res.data.data.merchantTranId);
        setOnlyaccountpay(res?.data?.data);
        setStatusapi(res?.data?.data?.success);
      }
    } catch (error) {
      console.error('Error during payment collection:', error);
    }
  };

  const handleCloseFade = () => {
    setOpen(false);
    setFailureView(false);
    setSuccessView(false);
    setSessionExpired(false);
    setDrawerOpen2(false);
    setDrawerOpen(false);
  };

  const handleClosePayment = async () => {
    try {
      const response = await Axios.post(
        'https://www.bizzbrains.com/bizzbrain-payment/api/cancelTransaction',
        {
          api_key: apiKey,
          api_secrets: apiSecret,
          txn_id: merchantTranId
        }
      );

      if (response.data.status == 200) {
        setDrawerOpen2(false);
        setSessionMessage({
          message: response.data.message,
          type: 'success',
          open: true,
          vertical: 'top',
          horizontal: 'right'
        });
        // window.location.reload();
      } else {
        setDrawerOpen2(false);
        setSessionMessage({
          message: response.data.message,
          type: 'error',
          open: true,
          vertical: 'top',
          horizontal: 'right'
        });
      }
    } catch (error) {
      console.error('Error during payment collection:', error);
    }
  };
  const handleCancelPayment = () => {
    if (accountName) {
      setDrawerOpen2(true);
    }
    setTimeLeft(11 * 60);
    setShowQRCode(false);
  };

  const handleShowQRCode = () => {
    setShowQRCode(true);
  };
  console.log('showQRCode', typeof showQRCode);

  useEffect(() => {
    const fetchQRCode = async () => {
      const uniqueOrderId = generateUniqueOrderId();
      if (showQRCode) {
        setQrcodeLoader(true);
        try {
          const response = await Axios.post(
            'https://www.bizzbrains.com/bizzbrain-payment/api/qrcode',
            {
              api_key: apiKey,
              api_secrets: apiSecret,
              order_id: uniqueOrderId,
              amount: formState.values.amount
            }
          );

          if (response.data.status === 200) {
            console.log('response', response);
            setMerchantTranId(response.data.data.merchantTranId);
            setQR(response.data.data.qr_code);
            setStatusqrcode(response.data.data.success);
            setQrcodeLoader(false);
          }
        } catch (error) {
          console.error('Error during payment collection:', error);
          setQrcodeLoader(false);
        }

        const timer = setInterval(() => {
          setTimeLeft(prevTime => {
            if (prevTime <= 0) {
              clearInterval(timer);
              setShowQRCode(false);
              setSessionExpired(true);
              return 0;
            }
            return prevTime - 1;
          });
        }, 1000);

        return () => clearInterval(timer);
      }
    };

    fetchQRCode();
  }, [showQRCode, apiKey, apiSecret, remainingAmount, setShowQRCode]);

  useEffect(() => {
    let interval;

    if (drawerOpen2 || showQRCode) {
      interval = setInterval(async () => {
        try {
          const response = await Axios.post(
            'https://www.bizzbrains.com/bizzbrain-payment/api/getTransaction',
            {
              api_key: apiKey,
              txn_id: merchantTranId,
              api_secrets: apiSecret
            }
          );

          if (response.data.data.status === 'FAILURE') {
            // const businessid = localStorage.getItem('businessid');

            setSessionMessage({
              message: response.data.message,
              type: 'error',
              open: true,
              vertical: 'top',
              horizontal: 'right'
            });
            {
              showQRCode && setDrawerOpen2(true);
            }
            setFailureView(true);
            clearInterval(interval);
          }

          if (response.data.data.status === 'SUCCESS') {
            const businessid = localStorage.getItem('businessid');
            setSessionMessage({
              message: response.data.message,
              type: 'success',
              open: true,
              vertical: 'top',
              horizontal: 'right'
            });
            {
              showQRCode && setDrawerOpen2(true);
            }
            setSuccessView(true);
            clearInterval(interval);
            setOnlyaccountpayqrcode(response.data.data);

            if (showQRCode) {
              const formData = new FormData();
              formData.append('amount', formState.values.amount);
              formData.append('business_id', businessid);
              formData.append('transaction_id', merchantTranId);
              formData.append(
                'status',
                statusqrcode === 'true' ? 'success' : 'fail'
              );
              formData.append(
                'payment_response',
                JSON.stringify(response.data.data)
              );

              try {
                setLoading(true);
                const response = await api.post('business/updateWalletAmount', {
                  data: formData
                });

                if (response?.success === true) {
                  setLoading(false);
                  setSessionMessage({
                    message: response.message,
                    type: 'success',
                    open: true,
                    vertical: 'top',
                    horizontal: 'right'
                  });
                  handleMainDialog();
                  currentBalance();

                  window.location.reload();
                } else {
                  setLoading(false);
                  setSessionMessage({
                    message: response.message,
                    type: 'error',
                    open: true,
                    vertical: 'top',
                    horizontal: 'right'
                  });
                }
              } catch (error) {
                setLoading(false);
                console.error(error);
              }
            } else {
              const formData = new FormData();
              formData.append('amount', formState.values.amount);
              formData.append('business_id', businessid);
              formData.append('transaction_id', merchantTranId);
              formData.append(
                'status',
                statusapi === 'true' ? 'success' : 'fail'
              );
              formData.append(
                'payment_response',
                JSON.stringify(onlyaccountpay)
              );

              try {
                setLoading(true);
                const response = await api.post('business/updateWalletAmount', {
                  data: formData
                });

                if (response?.success === true) {
                  setLoading(false);
                  setSessionMessage({
                    message: response.message,
                    type: 'success',
                    open: true,
                    vertical: 'top',
                    horizontal: 'right'
                  });
                  handleMainDialog();
                  currentBalance();

                  window.location.reload();
                } else {
                  setLoading(false);
                  setSessionMessage({
                    message: response.message,
                    type: 'error',
                    open: true,
                    vertical: 'top',
                    horizontal: 'right'
                  });
                }
              } catch (error) {
                setLoading(false);
                console.error(error);
              }
            }
          }
        } catch (error) {
          console.error('Error during payment collection:', error);
        }
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [
    drawerOpen2,
    merchantTranId,
    onlyaccountpay,
    statusapi,
    statusqrcode,
    onlyaccountpayqrcode
  ]);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const handleChange = (event, newValue) => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  // const features = JSON.parse(key_features);

  const handleVerifyUpiId = async event => {
    const inputUpiId = event.target.value;

    try {
      const response = await Axios.post(
        'https://www.bizzbrains.com/bizzbrain-payment/api/validateUPI',
        {
          api_key: apiKey,
          api_secrets: apiSecret,
          'virtual-address': inputUpiId
        }
      );

      if (response.status == 200) {
        setUPIid(inputUpiId);
        setAccountName(response.data.data.maskName);
        setErrorMessage('');
      } else {
        setErrorMessage(response.message);
        setAccountName();
      }
    } catch (error) {
      setErrorMessage('Session has expired. Please try again.');
      setAccountName('');
      setUPIid();
    }
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleKeyPress = event => {
    if (event.key === 'e' || isNaN(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <>
      {sessionMessage && (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={sessionMessage.open}
          autoHideDuration={3000}
          onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity={sessionMessage.type}>
            {sessionMessage.message}
          </Alert>
        </Snackbar>
      )}

      <Dialog open={open} fullWidth>
        {/* <Dialog open={open} onClose={handleMainDialog} fullWidth> */}
        <DialogTitle className={classes.dialogUpper}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
            <Typography className={classes.modal_heading}>
              Add Wallet
            </Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleMainDialog}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
            <Grid item xs={12}>
              <InputLabel
                className={classes.input_label_amount}
                htmlFor="input-with-icon-adornment">
                Amount
              </InputLabel>
              <TextField
                fullWidth
                name="amount"
                type="number"
                className={classes.input}
                error={hasError('amount')}
                // onKeyPress={handleKeyPress}
                helperText={
                  hasError('amount') ? formState.errors.amount[0] : null
                }
                placeholder="Enter your add wallet amount"
                onChange={handleChange}
                value={formState.values.amount || ''}
                InputProps={{
                  className: classes.form__input__amount,
                  startadornment: <InputAdornment position="start" />
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                className={classes.input_label1}
                htmlFor="input-with-icon-adornment">
                Pay using UPI Id
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                onChange={handleVerifyUpiId}
                disabled={!formState.values.amount}
                placeholder="Enter UPI Id"
                InputProps={{
                  className: classes.form__input,
                  startAdornment: (
                    <InputAdornment position="start">{''}</InputAdornment>
                  )
                }}
              />
              {ErrorMessage && (
                <Typography className={classes.redText}>
                  {ErrorMessage}
                </Typography>
              )}
              {accountName && (
                <Typography className={classes.walletAmount2}>
                  {accountName}
                </Typography>
              )}
            </Grid>
            <InputLabel
              className={classes.input_label}
              htmlFor="input-with-icon-adornment">
              Pay using QR Code
            </InputLabel>
            {!showQRCode && (
              <Button
                variant="outlined"
                disabled={!formState.values.amount}
                color="primary"
                className={classes.qrButton}
                onClick={handleShowQRCode}
                fullWidth>
                Show QR Code
              </Button>
            )}

            {showQRCode && (
              <div className={classes.qrCodeContainer}>
                <Grid container spacing={4}>
                  {qrcodeLoader ? (
                    <Grid
                      item
                      xs={5}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                      <CircularProgress size={24} color="inherit" />
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={5}>
                        <img
                          // src="https://via.placeholder.com/150" // Replace with your QR code image source
                          src={QR} // Replace with your QR code image source
                          alt="QR Code"
                          className={classes.qrCodeImage}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={7} className={classes.qrContainer}>
                    <Box>
                      <DialogContentText className={classes.font7}>
                        Scan the QR using any UPI app on your phone.
                      </DialogContentText>
                      <DialogContentText>
                        <img
                          width={20}
                          height={20}
                          src="/images/googlepay.svg"
                          alt="UPI App Icon"
                        />{' '}
                        <img
                          width={20}
                          height={20}
                          src="/images/phonepe.svg"
                          alt="UPI App Icon"
                        />{' '}
                        <img
                          width={20}
                          height={20}
                          src="/images/cred_circle.png"
                          alt="UPI App Icon"
                        />{' '}
                        <img
                          width={20}
                          height={20}
                          src="/images/paytm.svg"
                          alt="UPI App Icon"
                        />{' '}
                        <img
                          width={20}
                          height={20}
                          src="/images/bhim.svg"
                          alt="UPI App Icon"
                        />{' '}
                      </DialogContentText>
                      <DialogContentText className={classes.font7}>
                        QR Code is valid for <br />{' '}
                        <span className={classes.redText}>
                          {formatTime(timeLeft)}
                        </span>{' '}
                        minutes
                      </DialogContentText>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
          {sessionExpired && (
            <DialogContentText className={classes.font7}>
              <span className={classes.redText}>Session expired</span>{' '}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions className={classes.model_buttons2}>
          {/* <Box
            className={classes.PaymentDetails}
            fullWidth
            onClick={handleDrawerOpen}>
            Payment : {remainingAmount} <br />
            <span className={classes.viewText}> view details</span>
          </Box> */}

          <Button
            disabled={!accountName || !formState.values.amount || showQRCode}
            className={classes.model_button2}
            variant="contained"
            color="primary"
            fullWidth
            onClick={handlePaybutton}>
            Add Wallet
          </Button>
        </DialogActions>
        <Fade direction="bottom" in={drawerOpen2} mountOnEnter unmountOnExit>
          <div className={classes.drawerContent2}>
            {!successView && !failureView && (
              <Typography className={classes.walletAmount}>
                Please accept the request from <br />
                Bizzbrain's VPA on your UPI app
              </Typography>
            )}
            {successView && (
              <Typography className={classes.walletAmount}>
                Transaction Successful!
              </Typography>
            )}
            {failureView && (
              <Typography className={classes.walletAmount}>
                Transaction Failed!
              </Typography>
            )}
            <Grid item xs={5}>
              {!successView && !failureView && (
                <img
                  src="/images/Q3dgiTn9L4.gif" // Replace with your QR code image source
                  alt="Procceing"
                  className={classes.qrCodeImage}
                />
              )}
              {successView && (
                <img
                  src="/images/PayementSuccess.svg" // Replace with your QR code image source
                  alt="Procceing"
                  className={classes.qrCodeImage}
                />
              )}
              {failureView && (
                <img
                  src="/images/PayementFailed.svg" // Replace with your QR code image source
                  alt="Procceing"
                  className={classes.qrCodeImage}
                />
              )}
            </Grid>
            {successView ||
              (failureView && (
                <Button
                  className={classes.model_button2}
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleCloseFade}>
                  Close
                </Button>
              ))}
            {!successView && !failureView && (
              <Button
                className={classes.model_button2}
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleClosePayment}>
                Cancel payment
              </Button>
            )}
          </div>
        </Fade>
      </Dialog>
    </>
  );
};

export default Addwallet;
